import './bootstrap.js';
import 'phoenix_html';

import 'react-hot-loader'; // Required to be loaded before react and react-dom
import * as React from 'react';
import ReactDOM from 'react-dom';

// Styles
import 'react-popper-tooltip/dist/styles.css';
import 'react-toggle/style.css';
import '../css/app.css';

import App from './app.js';

// Load router for pure js screens
const reactContainer = document.getElementById('react-root');

if (reactContainer) {
  ReactDOM.render(<App />, reactContainer);
}
