import * as React from 'react';
import { hot } from 'react-hot-loader/root';
import { ApolloProvider } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, BrowserRouter, Switch } from 'react-router-dom';
import {
  DefaultToastContainer,
  ToastProvider,
} from 'react-toast-notifications';

import AppLayout from 'appComponents/AppLayout';
import LoadingView from 'components/LoadingView';
import AuthProvider from 'screens/Auth/AuthProvider';

import 'config/i18n/i18n';
import config from 'constants/config';
import createApolloClient from 'config/apollo.js';


const ToastContainer = props => (
  <DefaultToastContainer {...props} style={{ zIndex: 9999 }} />
);

// Import async loaded screens
const Contest = importAsync(() => import('./screens/Contest'));
const Core = importAsync(() => import('./screens/Core'));
const DashboardScreen = importAsync(() => import('./screens/Dashboard'));
const Promotion = importAsync(() => import('./screens/Promotion'));
const Reporting = importAsync(() => import('./screens/Reporting/Reporting'));
const SalesScreen = importAsync(() => import('./screens/Sales/Sales'));

function App() {
  return (
    <ToastProvider components={{ ToastContainer }}>
      <Translations />
      <AuthProvider>
        {accessToken => (
          <ApolloProvider client={createApolloClient({ token: accessToken })}>
            <BrowserRouter>
              <AppLayout>
                <Switch>
                  <Route path="/admin/contest">
                    <Contest />
                  </Route>
                  <Route path="/admin/core">
                    <Core />
                  </Route>
                  <Route path="/admin/dashboard">
                    <DashboardScreen />
                  </Route>
                  <Route path="/admin/promotion">
                    <Promotion />
                  </Route>
                  <Route path="/admin/reporting">
                    <Reporting />
                  </Route>
                  <Route path="/admin/sales">
                    <SalesScreen />
                  </Route>
                  <Route path="/">
                    <Redirect to="/admin/dashboard" />
                  </Route>
                </Switch>
              </AppLayout>
            </BrowserRouter>
          </ApolloProvider>
        )}
      </AuthProvider>
    </ToastProvider>
  );
}

function importAsync(importFn) {
  const Component = React.lazy(importFn);
  return props => (
    <React.Suspense fallback={<LoadingView />}>
      <Component {...props} />
    </React.Suspense>
  );
}

export default hot(App);

export const Translations = React.memo(() => {
  // NOTE i18n
  const { t, i18n } = useTranslation();
  const changeLanguage = React.useCallback(value => i18n.changeLanguage(value), [i18n]);
  return !config.DEBUG ? null : <div className="fixed p-4" style={{ right: 0, bottom: 0, zIndex: 100 }}>
    <span className="mr-2">{ t('test') }</span>
    <button
      className={`btn btn-raised ${i18n.language === 'en' ?  'btn-primary' : ''}`}
      onClick={() => changeLanguage('en')}
    >English</button>
    <button
      className={`btn btn-raised ${i18n.language === 'de' ?  'btn-primary' : ''}`}
      onClick={() => changeLanguage('de')}
    >German</button>
  </div>;
});
