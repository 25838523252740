import * as React from 'react';
import classnames from 'classnames';

const placeholderUrl =
  'https://jackpot-media.s3.eu-central-1.amazonaws.com/employees/placeholder.png';

export default function Avatar({ className, url, size = 'md', ...props }) {
  return (
    <img
      {...props}
      alt="avatar"
      className={classnames(
        'bg-grey-light flex-none overflow-hidden rounded-full',
        {
          'w-6 h-6': size === 'xs',
          'w-8 h-8': size === 'sm',
          'w-12 h-12': size === 'md',
          'w-16 h-16': size === 'lg',
          'w-24 h-24': size === 'xl',
        },
        className
      )}
      src={url || placeholderUrl}
    />
  );
}
