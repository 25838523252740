import { UserAgentApplication } from 'msal';

export const requestScope = { scopes: ['user.read', 'profile'] };
export const msalApp = new UserAgentApplication({
  auth: {
    clientId: process.env.AZURE_APP_ID,
    authority: process.env.AZURE_AUTHORITY,
    validateAuthority: true,
    postLogoutRedirectUri: process.env.ADMIN_UI_URL,
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true,
  },
});
