import React from 'react';
import classnames from 'classnames';

export default function Dropdown({
  align = 'right',
  className,
  options = [],
  trigger,
}) {
  const containerNode = React.useRef();
  const [isOpen, setOpen] = React.useState(false);

  React.useEffect(() => {
    if (open) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  function handleClickOutside(event) {
    if (containerNode.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  }

  return (
    <div
      className={classnames('inline-block relative', className)}
      ref={containerNode}
    >
      {React.cloneElement(trigger, {
        onClick: event => {
          event.preventDefault();
          setOpen(!isOpen);
        },
      })}
      {isOpen && (
        <aside
          className={classnames(
            'absolute border  bg-white shadow-lg z-50 -mt-1',
            { 'pin-r': align === 'right', 'pin-l': align === 'left' }
          )}
        >
          <ol className="list-reset py-2">
            {options.map(({ icon, label, disabled, onClick }) => (
              <li key={label}>
                <button
                  disabled={disabled}
                  className="flex items-center px-4 py-3 text-left whitespace-no-wrap w-full hover:bg-orange-lightest"
                  onClick={() => {
                    setOpen(false);
                    onClick();
                  }}
                >
                  {icon &&
                    React.cloneElement(icon, { className: 'mr-4', size: 18 })}
                  <span>{label}</span>
                </button>
              </li>
            ))}
          </ol>
        </aside>
      )}
    </div>
  );
}
