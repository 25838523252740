import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import Login from './Login';

export default function AuthProvider({ children }) {
  const accessToken = localStorage.getItem('AccessToken');

  if (!accessToken) {
    return (
      <BrowserRouter>
        <Switch>
          <Route path="/admin/login">
            <Login />
          </Route>
          <Route path="*">
            <Redirect
              to={{
                pathname: '/admin/login',
                state: { from: location.href },
              }}
            />
          </Route>
        </Switch>
      </BrowserRouter>
    );
  }

  return children({ accessToken });
}
