import * as React from 'react';
import classnames from 'classnames';

import Spinner from './Spinner';

export default function LoadingView({ className }) {
  return (
    <div
      className={classnames(
        'flex flex-1 items-center justify-center',
        className
      )}
    >
      <Spinner />
    </div>
  );
}
