import gql from 'graphql-tag';
import * as React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import { Link, NavLink } from 'react-router-dom';

import MenuIcon from 'mdi-react/ChevronDownIcon';
import ContestIcon from 'mdi-react/GamepadVariantIcon';
import CoreIcon from 'mdi-react/CubeOutlineIcon';
import LogoutIcon from 'mdi-react/PowerStandbyIcon';
import PromotionIcon from 'mdi-react/BullhornOutlineIcon';
import ReportsIcon from 'mdi-react/ChartLineIcon';
import SalesIcon from 'mdi-react/SaleIcon';

import LogoImage from 'assets/logo.png';

import ProtectedByRole from 'appComponents/ProtectedByRole';
import Avatar from 'components/Avatar';
import Dropdown from 'components/Dropdown';

import LogoutProvider from 'screens/Auth/LogoutProvider';

const nullUser = { roles: [] };
const GetCurrentUser = gql`
  query GetCurrentUser {
    currentUser {
      avatarUrl
      currentYear
      id
      name
      roles
    }
  }
`;

export const CurrentUserContext = React.createContext(nullUser);

export default function AppLayout({ children }) {
  const { t } = useTranslation();
  const { data, error, loading } = useQuery(GetCurrentUser, {
    fetchPolicy: 'cache-and-network',
  });

  const currentUser = data?.currentUser || nullUser;

  return (
    <CurrentUserContext.Provider value={currentUser}>
      <nav className="app-nav bg-black border-t-3 border-primary flex">
        <Link className="app_nav__item" to="/admin/dashboard">
          <img alt="abi.jackpot" className="h-8" src={LogoImage} />
        </Link>
        <ProtectedByRole role="contest">
          <NavModule label="abi.jackpot" basePath="/admin/contest">
            <Link to="/admin/contest/dashboard">Dashboard</Link>
            <Link to="/admin/contest/regions">Regionen</Link>
            <Link to="/admin/contest/teams">Abi-Jahrgänge</Link>
            <Link to="/admin/contest/users">Spieler</Link>
            <Link to="/admin/contest/challenges">Challenges</Link>
            <Link to="/admin/contest/news_entries">News</Link>
          </NavModule>
        </ProtectedByRole>

        <ProtectedByRole role="promotion">
          <NavModule label="Promotion" basePath="/admin/promotion">
            <Link to="/admin/promotion/dashboard">Dashboard</Link>
            <Link to="/admin/promotion/teams">Teams</Link>
            <Link to="/admin/promotion/regions">Regionen</Link>
          </NavModule>
        </ProtectedByRole>

        <ProtectedByRole role="sales">
          <NavModule label="Sales" basePath="/admin/sales">
            <Link to="/admin/sales/dashboard">Mein Dashboard</Link>
            <Link to="/admin/sales/deals">Meine Deals</Link>
            <NavSpacer />
            <Link to="/admin/sales/meetings">Kick-Off Meetings</Link>
            <Link to="/admin/sales/inquiries">Website-Anfragen</Link>
            <ProtectedByRole role="salesSupervisor">
              <NavSpacer />
              <Link to="/admin/sales/team">Teamübersicht</Link>
              <Link to="/admin/sales/activity_report">Aktivitätsübersicht</Link>
            </ProtectedByRole>
            <Link to="/admin/sales/app_statistics">
              {t('sales.app-statistics.app-statistics')}
            </Link>
          </NavModule>
        </ProtectedByRole>

        <ProtectedByRole role="reporting">
          <NavModule label="Reports" basePath="/admin/reporting" />
        </ProtectedByRole>

        <ProtectedByRole role="core">
          <NavModule label="Stammdaten" basePath="/admin/core">
            <Link to="/admin/core/schools">Schulen</Link>
            <Link to="/admin/core/schools_reassignment">{t('sales.schools-reassignment.schools-reassignment')}</Link>
          </NavModule>
        </ProtectedByRole>

        <a className="app_nav__item ml-auto" href="">
          <Avatar
            className="mr-2"
            size="sm"
            url={data?.currentUser?.avatarUrl}
          />
          {data?.currentUser?.name}
        </a>
        <LogoutProvider>
          {({ logout }) => (
            <button className="px-4 text-primary50" onClick={logout}>
              abmelden
            </button>
          )}
        </LogoutProvider>
      </nav>
      {children}
    </CurrentUserContext.Provider>
  );
}

function NavModule({ basePath, children, label, options = [] }) {
  const containerNode = React.useRef();
  const [isOpen, setOpen] = React.useState(false);

  React.useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  function handleClickOutside(event) {
    if (containerNode.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  }

  const hasChildren = React.Children.count(children) > 0;

  return (
    <div className="relative" ref={containerNode}>
      <NavLink
        className="app_nav__item"
        onClick={event => {
          if (hasChildren) {
            event.preventDefault();
            setOpen(!isOpen);
          }
        }}
        to={basePath}
      >
        {label}{' '}
        {hasChildren && <MenuIcon className="ml-1 text-grey" size="18" />}
      </NavLink>
      {isOpen && (
        <aside className="absolute pin-l bg-black shadow-lg z-50">
          <ul className="list-reset py-3" style={{ minWidth: '12rem' }}>
            {React.Children.map(children, child => (
              <li>
                {React.cloneElement(child, {
                  className: 'app_nav__subitem',
                  onClick: () => setOpen(false),
                })}
              </li>
            ))}
          </ul>
        </aside>
      )}
    </div>
  );
}

function NavSpacer() {
  return <hr className="border-t border-grey-darkest" />;
}
