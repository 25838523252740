import { msalApp } from './msalConfig.js';

export default function LogoutComponent({ children }) {
  function handleLogout() {
    localStorage.removeItem('AccessToken');
    msalApp.logout();
  }

  return children({ logout: handleLogout });
}
