import * as React from 'react';
import { useHistory, useLocation, Redirect } from 'react-router-dom';
import { msalApp, requestScope } from './msalConfig.js';
import Axios from "axios";

export default function Login() {
  const history = useHistory();
  const location = useLocation();

  if (localStorage.getItem('AccessToken')) {
    // Use full reload to force refetching of access token + we do not need any history state anymore
    window.location = '/admin/dashboard';
  }

  async function handleLogin() {
    // Get a valid token for hasura
    let tokenRequest = {
      method: 'POST',
      url: 'https://service.abiagenten.de/tokenizer',
      headers: {
        'Content-Type': 'application/json'
      },
      data: {

      }
    };

    const tokenRes = await Axios(tokenRequest)
    localStorage.setItem('hasuraToken', tokenRes.data.token);

    msalApp.loginPopup(requestScope).then(response => {
      localStorage.setItem('AccessToken', response.idToken.rawIdToken);
      // Use full reload to force refetching of access token + we do not need any history state anymore
      window.location = location?.state?.from || '/admin';
    });
  }

  return (
    <div className="w-full h-full flex justify-center items-center">
      <div className="card mt-16 p-4" style={{ width: '36rem' }}>
        <h2 className="card-title">abiagenten Back-Office</h2>
        <p className="mb-4 text-grey-darkest">
          Das Backoffice ist nur über deinen Office 365 Unternehmensaccount
          erreichbar. Durch Klick des nachfolgenden Buttons, wirst Du zu
          Microsoft weitergeleitet. Sofern die Anmeldung erfolgreich ist und ein
          Profil im Back-Office für Dich angelegt wurde, wirst du direkt
          eingeloggt.
        </p>

        <button className="btn btn-primary btn-raised" onClick={handleLogin}>
          Office 365 Anmeldung
        </button>

        <p className="mt-4 italic text-sm text-grey-darker">
          Sofern die Anmeldung nicht funktioniert, wende Dich bitte an unseren
          IT-Support!
        </p>
      </div>
    </div>
  );
}
