import * as AbsintheSocket from '@absinthe/socket';
import { createAbsintheSocketLink } from '@absinthe/socket-apollo-link';
import { Socket as PhoenixSocket } from 'phoenix';
import ApolloClient from 'apollo-client';
import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from 'apollo-cache-inmemory';
import { msalApp } from 'screens/Auth/msalConfig.js';

import gql from 'graphql-tag';

import introspectionQueryResultData from './introspection.json';

// Local typeDefs
const typeDefs = gql`
  extend type Query {
    isConnected: Boolean!
  }
`;

// Local resolvers
const resolvers = {};

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

export default function({ token }) {
  const url = new URL(process.env.ADMIN_SOCKET, window.location.href);
  url.protocol = url.protocol.replace('http', 'ws');

  const socket = new PhoenixSocket(url.href, {
    params: { token },
  });

  const link = createAbsintheSocketLink(AbsintheSocket.create(socket));
  const cache = new InMemoryCache({ fragmentMatcher });
  const client = new ApolloClient({ cache, link, typeDefs, resolvers });

  // Warm cache cache
  cache.writeData({ data: { isConnected: false } });

  // Monitor web socket connection to notify users about their connectivity
  function setConnectionStatus(isConnected) {
    client.writeQuery({
      query: gql`
        query GetConnectionStatus {
          isConnected @client
        }
      `,
      data: { isConnected },
    });
  }
  socket.onClose(() => setConnectionStatus(false));
  socket.onOpen(() => setConnectionStatus(true));
  socket.onError(error => {
    console.error('SocketError', error);
    localStorage.removeItem('AccessToken');
    location.reload();
  });

  return client;
}
