const config = {
  ...process.env,
  DEBUG: process.env.REACT_APP_DEBUG === String(true),

  LANGUAGE: process.env.REACT_APP_LANGUAGE || '',

  PRODUCTION_LINE_WEBSITE: process.env.PRODUCTION_LINE_WEBSITE || '',

  DIGITAL_OCEAN: process.env.DIGITAL_OCEAN || '',
  COMPETITOR_OFFER_DOWNLOAD_PATH: process.env.COMPETITOR_OFFER_DOWNLOAD_PATH || '',

  ADMIN_SOCKET: process.env.ADMIN_SOCKET || '',
  ADMIN_UI_URL: process.env.ADMIN_UI_URL || '',

  AZURE_APP_ID: process.env.AZURE_APP_ID || '',
  AZURE_AUTHORITY: process.env.AZURE_AUTHORITY || '',

  HASURA: process.env.HASURA || '',

  JAVA_GRAPH_QL_SERVICE: process.env.JAVA_GRAPH_QL_SERVICE || '',
  JAVA_GRAPH_QL_PREFIX: process.env.JAVA_GRAPH_QL_PREFIX || '',
  JAVA_GRAPH_QL_VERSION: process.env.JAVA_GRAPH_QL_VERSION || '',
  JAVA_GRAPH_QL_APPLICATION: process.env.JAVA_GRAPH_QL_APPLICATION || '',
  JAVA_GRAPH_QL_PATH: process.env.JAVA_GRAPH_QL_PATH || '',
  JAVA_GRAPH_QL_INTROSPECTIVE: process.env.JAVA_GRAPH_QL_INTROSPECTIVE || '',

  SURVEY_BUILDER_WEBSITE: process.env.SURVEY_BUILDER_WEBSITE || '',
  SURVEY_CLIENT_WEBSITE: process.env.SURVEY_CLIENT_WEBSITE || '',

  JAVA_GRAPHQL_INTROSPECTIVE_AUTH_HEADER: process.env.JAVA_GRAPHQL_INTROSPECTIVE_AUTH_HEADER || '',
  JAVA_GRAPHQL_INTROSPECTIVE_AUTH_BASIC_TITLE: process.env.JAVA_GRAPHQL_INTROSPECTIVE_AUTH_BASIC_TITLE || '',
  JAVA_GRAPHQL_INTROSPECTIVE_AUTH_BASIC_VALUE: btoa(process.env.JAVA_GRAPHQL_INTROSPECTIVE_AUTH_BASIC_VALUE) || '',
};

// ON debug mode for production using url params
config.DEBUG = config.DEBUG ? true : /show_DEBUG/.test(window.location.href);

config.DEBUG
&&console.info('%c CONFIG ', 'background: #EC1B24; color: #000; font-weight: bolder; font-size: 30px;'
  , '\n full ENV:', process.env
  , '\n NODE_ENV:', process.env.NODE_ENV
  , '\n REACT_APP_ENV:', process.env.REACT_APP_ENV
  , '\n config:', config
);

export default config;
