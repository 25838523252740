import * as React from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

const GetCurrentUser = gql`
  query GetCurrentUser {
    currentUser {
      id
      role
      roles
    }
  }
`;

export default function ProtectedByRole({ children, role, ...rest }) {
  const { data, error, loading } = useQuery(GetCurrentUser);
  const currentUser = data?.currentUser || { roles: [] };

  if (currentUser.role === 'ADMIN' || currentUser.roles.includes(role)) {
    return React.Children.map(children, child =>
      React.cloneElement(child, rest)
    );
  }

  return null;
}
